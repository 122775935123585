/* General styles */
.container {
  max-width: 1600px;
  margin: 0 auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
}

.card-container {
  max-width: 640px;
  width: 100%;
  margin: 2rem auto;
}

.card {
  background-color: white;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  overflow: hidden;
  max-width: 310px;
}

/* Card before after slider */
.card-image {
  position: relative;
}


/* Card content */
.card-content {
  padding: 1rem;
}

.card-title {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
  color: #4a5568;
}

.card-description {
  font-size: 1rem;
  color: #1a202c;
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}

.btn-12{
  position: relative;
  right: 20px;
  bottom: 20px;
  border:none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.btn-12 span {
  background: rgb(0,172,238);
background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin:0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.btn-12 span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.btn-12:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.address {
  color: #4a5568;
  padding-top: 1rem;
}

/* Property details */
.google-reviews {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: #f9fafb;
  border-top: 1px solid #e2e8f0;
  border-radius: 0 0 10px 10px;
  color: #4a5568;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.review-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.review-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.review-content {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #2d3748;
}

.review-name {
  font-weight: bold;
  color: #1a202c;
}

.review-date {
  font-size: 0.85rem;
  color: #a0aec0;
}

.review-rating {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.star-icon {
  width: 16px;
  height: 16px;
  margin-right: 0.2rem;
  fill: #f6ad55;
  align-items: center;
}

.review-card:hover {
  transform: translateY(-5px);
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.details-item {
  flex: 1;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
  color: #718096;
}

.bold-text {
  color: #1a202c;
  font-weight: bold;
}

/* Realtor section */
.realtor-section {
  padding: 1rem;
  border-top: 1px solid #e2e8f0;
  background-color: #f7fafc;
}

.realtor-info {
  display: flex;
  align-items: center;
}

.realtor-image img {
  background-size: cover;
  background-position: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  margin-right: 0.75rem;
}

.realtor-name {
  font-weight: bold;
  color: #1a202c;
}

.realtor-contact {
  font-size: 0.875rem;
  color: #4a5568;
}

/* Responsive styles */
@media (max-width: 768px) {
  .card-content {
    padding: 0.75rem;
  }

  .card-title {
    font-size: 1.1rem;
    letter-spacing: 0.08rem;
  }

  .card-description {
    font-size: 0.95rem;
  }

  .custom-btn {
    width: 110px;
    height: 35px;
    padding: 8px 20px;
    font-size: 0.9rem;
  }

  .btn-12 {
    width: 110px;
    height: 35px;
  }

  .google-reviews {
    gap: 1rem;
    padding: 1rem;
  }

  .review-profile-image {
    width: 40px;
    height: 40px;
  }

  .realtor-info {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .card-content {
    padding: 0.5rem;
  }

  .card-title {
    font-size: 1rem;
    letter-spacing: 0.05rem;
  }

  .card-description {
    font-size: 0.9rem;
  }

  .custom-btn,
  .btn-12 {
    width: 100px;
    height: 30px;
    padding: 5px 15px;
    font-size: 0.8rem;
  }

  .review-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .review-rating,
  .review-name,
  .review-date {
    font-size: 0.8rem;
  }

  .realtor-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .realtor-image img {
    width: 2rem;
    height: 2rem;
  }

  .realtor-contact {
    font-size: 0.75rem;
  }
}