/* General styles for the carousel */
/* Ensure carousel and cards adjust properly */
.reviews-carousel {
  margin: 0 auto; 
  max-width: 100%; 
  overflow: hidden; /* Prevent overflow */
  padding-top: 20px;
  padding-bottom: 50px;
}

.review-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
  flex: 0 0 calc(100% / 7 - 20px); /* Adjust dynamically based on slidesToShow */
  margin: 0 10px; /* Space between cards */
  box-sizing: border-box; /* Include padding/border in width */
  height: auto; /* Remove fixed height for better responsiveness */
}

/* Hover effect */
.review-card:hover {
  transform: translateY(-10px);
}

/* Review Header (Author and Rating) */
.review-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.review-author-image {
  width: 50px; /* Profile image size */
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.review-author-details p {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.rating {
  display: flex;
  gap: 4px;
  margin-top: 8px;
}

.rating svg {
  fill: #ffb400;
  width: 18px;
  height: 18px; /* Adjust star size */
}

/* Review Text */
.card-text p {
  font-size: 13px;
  color: #555;
  line-height: 1.4;
  margin-top: 10px;
  max-height: 200px; /* Limit text height */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis */
}

/* "See More" Button */
.see-more {
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #0073e6;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s;
}

.see-more:hover {
  color: #005bb5;
}

/* Slick Carousel Style Adjustments */
.slick-prev,
.slick-next {
  background-color: #0073e6;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  padding: 10px;
  z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #005bb5;
}

.slick-prev {
  left: -40px; /* Position arrows properly */
}

.slick-next {
  right: -40px;
}

.slick-dots {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.slick-dots li button:before {
  font-size: 12px;
  color: #0073e6;
}

.slick-dots li.slick-active button:before {
  color: #005bb5;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.modal-author-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.modal-author-details p {
  font-size: 16px;
  font-weight: bold;
}

.modal-body {
  font-size: 14px;
  line-height: 1.6;
  color: #555;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive Adjustments for screens <= 768px */
@media (max-width: 768px) {
  .reviews-carousel {
    padding: 0;
    overflow: hidden; /* Prevent unnecessary scrolling */
  }

  .review-card {
    flex: 0 0 100%; /* Card takes full width */
    margin: 0 auto; /* Center the card */
    width: 100%; /* Full width of the container */
    max-width: 100%; /* No restrictions */
    height: auto; /* Let height adjust naturally */
  }

  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .slick-prev,
  .slick-next {
    font-size: 18px;
  }
}

/* For screens <= 630px */
@media (max-width: 630px) {
  .reviews-carousel {
    padding: 0;
  }

  .review-card {
    flex: 0 0 100%; /* Full screen width for smaller screens */
    margin: 0 auto; /* Center the card */
  }

  .slick-prev,
  .slick-next {
    display: none; /* Hide arrows if needed for very small screens */
  }
}

.slick-slide {
  display: flex;
  justify-content: center; /* Center cards within each slide */
}